import {
  Box,
  Stack,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import type { ControlViolations } from '@repo/api-gw-sdk';

import { BorderlessTableCell } from '@/components/table/components';
import { ControlsSeverity } from '@/data/inventory/data';

export const ControlIndicator = ({
  backgroundColor,
}: {
  backgroundColor: string;
}) => {
  return (
    <Box
      component={'span'}
      className='rounded-full min-w-[12px] min-h-[12px] w-[12px] h-[12px] ml-[4px] inline-block'
      sx={{
        backgroundColor,
      }}
    />
  );
};

export const controlKeysPriorities = ['high', 'medium', 'low', 'muted'];

export const ControlsColumnIndicators = ({
  controlViolations,
}: {
  controlViolations: ControlViolations | undefined;
}) => {
  if (!controlViolations) {
    return null;
  }

  const circles = controlKeysPriorities.reduce<string[]>((agg, priority) => {
    const amount = (controlViolations as Record<string, number | undefined>)[
      priority
    ];
    Array.from(Array(amount ?? 0)).forEach(() => {
      agg.push(priority);
    });
    return agg;
  }, []);

  return (
    <Tooltip
      title={
        <Stack>
          <Typography
            className='mb-[8px]'
            color='var(--mui-palette-common-white)'
          >
            Control violations
          </Typography>
          <Table>
            <TableBody>
              {controlKeysPriorities.map((priority) => {
                const amount = (
                  controlViolations as Record<string, number | undefined>
                )[priority];
                return (
                  amount && (
                    <TableRow key={priority} className='mt-[4px] py-[2px]'>
                      <BorderlessTableCell>
                        <ControlIndicator
                          backgroundColor={ControlsSeverity[priority].color}
                        />
                      </BorderlessTableCell>
                      <BorderlessTableCell>
                        <Typography
                          className='ml-[8px]'
                          color='var(--mui-palette-common-white)'
                        >
                          {amount}
                        </Typography>
                      </BorderlessTableCell>
                      <BorderlessTableCell>
                        <Typography
                          className='ml-[8px]'
                          color='var(--mui-palette-common-white)'
                        >
                          {ControlsSeverity[priority].title}
                        </Typography>
                      </BorderlessTableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      }
      className='ml-[8px]'
      slotProps={{
        tooltip: {
          sx: {
            width: '170px',
            background: 'rgba(39, 39, 66, 0.90);',
            borderRadius: '8px',
            padding: '12px',
          },
        },
      }}
    >
      <Stack direction={'row'} alignItems={'center'}>
        {circles.slice(0, 2).map((priority, index) => (
          <ControlIndicator
            key={`indicator_${index}`}
            backgroundColor={ControlsSeverity[priority].color}
          />
        ))}
        {circles.length > 2 && (
          <Typography className='ml-[4px]'>+{circles.length - 2}</Typography>
        )}
      </Stack>
    </Tooltip>
  );
};
