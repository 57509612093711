import { Stack, Tooltip, Typography } from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';

import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import BackupVaultPopperCard from './BackupPolicyPopperCard';

import { SnapshotIndicator } from '../snapshots/SnapshotIndicator';

export interface BackupPolicyTagProps {
  policy: BackupPolicy;
}

export default function BackupPolicyTag(props: BackupPolicyTagProps) {
  const { data: vaults, loading: vaultsLoading } = useBackupVaults();

  const distinctVaults = Array.from(
    new Set(props.policy.schedules.map((schedule) => schedule.vaultId))
  );

  return (
    <>
      <Tooltip
        leaveTouchDelay={2000}
        PopperProps={{
          sx: {
            width: '400px',
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'transparent',
              maxWidth: 'none',
            },
          },
        }}
        title={<BackupVaultPopperCard policy={props.policy} />}
      >
        <Stack
          alignSelf='center'
          display='inline-flex'
          alignItems='center'
          direction='row'
          sx={{
            border: '1px solid var(--mui-palette-background-dark)',
            borderRadius: '4px',
            padding: '3px 8px',
            margin: '4px',
          }}
        >
          {!vaultsLoading &&
            distinctVaults.map((vid, index) => {
              const v = vaults.find((v: BackupVault) => v.id === vid);
              return (
                <SnapshotIndicator
                  key={index}
                  backgroundColor={v?.backgroundColor || 'transparent'}
                  sx={{ mr: '8px' }}
                />
              );
            })}
          <Typography variant='body1' sx={{ fontSize: '12px' }}>
            {props.policy.name}
          </Typography>
        </Stack>
      </Tooltip>
    </>
  );
}
