import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import type { Control } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import { useDAL } from '@/data/dal';
import { ControlsSeverity } from '@/data/inventory/data';
import {
  isValueCondition,
  ListOperator,
  type Condition,
} from '@/types/advanceFilter';

import { ControlIndicator, controlKeysPriorities } from './controlIndicator';

import { Loader } from '../layout/loading';
import {
  controlId,
  controlLevel,
  mutedControlId,
} from '../queryBuilder/properties';

export const ControlsListFilter = ({
  priority,
  onChange,
  filter,
}: {
  priority: string;
  filter: Condition;
  onChange: (conditions: Condition[]) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.controls.get();

  if (!body?.controls.length) {
    return <Loader />;
  }

  const conditions = (!isValueCondition(filter) && filter?.conditions) || [];

  const groupedControls =
    body?.controls.reduce<Record<string, Control[]>>((acc, control) => {
      const priority = control.severity.toLowerCase();
      acc[priority] = acc[priority] || [];
      acc[priority].push(control);
      return acc;
    }, {}) || {};

  const priorities =
    priority === 'muted'
      ? controlKeysPriorities.filter((x) => x !== 'muted')
      : [priority];

  const condition = conditions.find(
    (x) =>
      isValueCondition(x) &&
      (x.property === controlId.name || x.property === mutedControlId.name)
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) || [];

  return (
    <FormControl variant='outlined' fullWidth>
      {priorities.map((p) => {
        return (
          <Stack key={p} className='mb-[12px]'>
            {priority === 'muted' && (
              <Typography variant='subtitle1' className='uppercase mb-[12px]'>
                {`${p}-severity controls`}
              </Typography>
            )}
            {groupedControls[p]
              ?.sort((a, b) => a.name?.localeCompare(b.name))
              .map(({ id, name }, index) => {
                return (
                  <Fragment key={id}>
                    <FormControlLabel
                      className='w-full flex items-start'
                      control={
                        <Checkbox
                          className='p-[8px]'
                          size='small'
                          checked={selectedResources.includes(id)}
                          onChange={(event, value) => {
                            const values = value
                              ? [...selectedResources, id]
                              : selectedResources.filter((x) => x !== id);

                            onChange(
                              conditions
                                .filter(
                                  (x) =>
                                    isValueCondition(x) &&
                                    x.property !== controlLevel.name &&
                                    x.property !== controlId.name &&
                                    x.property !== mutedControlId.name
                                )
                                .concat(
                                  values.length
                                    ? [
                                        {
                                          type: 'List' as const,
                                          property:
                                            priority === 'muted'
                                              ? mutedControlId.name
                                              : controlId.name,
                                          operator: ListOperator.In,
                                          value: values,
                                        },
                                      ]
                                    : []
                                )
                            );
                          }}
                        />
                      }
                      label={
                        <Stack direction={'row'} className='mt-[12px]'>
                          <ControlIndicator
                            backgroundColor={
                              ControlsSeverity[
                                priority === 'muted' ? 'muted' : p
                              ].color
                            }
                          />
                          <Typography className='ml-[12px] mt-[-2px]'>
                            {name}
                          </Typography>
                        </Stack>
                      }
                    />
                    {index !== groupedControls[p].length - 1 && (
                      <Divider sx={{ margin: '12px -24px' }} />
                    )}
                  </Fragment>
                );
              })}
          </Stack>
        );
      })}
    </FormControl>
  );
};
