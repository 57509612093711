import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import type {
  Control,
  InventoryResourceBackupStatusEnum,
} from '@repo/api-gw-sdk';
import classnames from 'classnames';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { BackupStatuses, ControlsSeverity } from '@/data/inventory/data';
import {
  createCombineCondition,
  isValueCondition,
  type Condition,
} from '@/types/advanceFilter';

import { BackupStatusIcon } from './backupStatusIcon';

import {
  ControlIndicator,
  controlKeysPriorities,
} from '../controls/controlIndicator';
import { ControlsListFilter } from '../controls/controlsListFilter';
import {
  backupStatus,
  controlId,
  mutedControlId,
} from '../queryBuilder/properties';
import { Icon } from '../shared/icon';

interface FilterProps {
  status: InventoryResourceBackupStatusEnum;
  onClick: (
    status: InventoryResourceBackupStatusEnum | undefined,
    severity?: string
  ) => void;
  selectedStatus: string | undefined;
}

export const ViolationsDetectedFilterRow = (
  props: FilterProps & {
    selectedLevel: string | undefined;
    renderFiltersPanel: (
      columnFilters: Condition | undefined,
      expandedPropertyName?: string
    ) => void;
    onFilterChange: (condition: Condition[]) => void;
    conditions: Condition[];
  }
) => {
  const { leftPanel } = useWorkspace();
  const dal = useDAL();
  const { body } = dal.controls.get();

  const condition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === controlId.name
  );

  const mutedCondition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === mutedControlId.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) ||
    (isValueCondition(mutedCondition) && mutedCondition.value) ||
    [];

  const severityWithFilteredControls = mutedCondition
    ? 'muted'
    : findFirstControl(
        selectedResources,
        body?.controls
      )?.severity.toLowerCase();

  return (
    <Stack>
      <BackupStatusFilterRow {...props} />
      <Stack className='my-[12px]'>
        {controlKeysPriorities.map((priority) => {
          const selected = props.selectedLevel === priority;

          return (
            <Stack
              key={priority}
              sx={(theme) => ({
                margin: '0 -24px',
                paddingLeft: '32px',
                borderLeft: '2px solid transparent',
                borderColor: selected ? '#272742' : 'transparent',
                backgroundColor: selected
                  ? 'var(--mui-palette-background-default)'
                  : 'transparent',
                cursor: selected ? 'default' : 'pointer',
                ...(selected
                  ? {}
                  : {
                      '&:hover': {
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#272742' : 'white',
                      },
                    }),
              })}
              onClick={() => props.onClick(undefined, priority)}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  borderLeft: '1px solid var(--mui-palette-divider)',
                  padding: '4px 0 4px 20px',
                }}
              >
                <ControlIndicator
                  backgroundColor={ControlsSeverity[priority].color}
                />
                <Typography className='ml-[12px] flex-grow'>
                  {ControlsSeverity[priority].title}
                </Typography>
                {severityWithFilteredControls === priority && (
                  <Box
                    className='rounded inline-block mr-[10px] h-[8px] w-[8px]'
                    sx={{
                      backgroundColor: 'currentColor',
                    }}
                  />
                )}
                <Tooltip
                  title={
                    priority === 'muted'
                      ? 'Filter by muted violated controls'
                      : 'Filter by violated controls'
                  }
                >
                  <Icon
                    iconClass='material-symbols-arrow-right-rounded'
                    className={classnames('mr-[18px]', {
                      collapse: !(
                        selected || severityWithFilteredControls === priority
                      ),
                    })}
                    onClick={(event) => {
                      leftPanel.setComponent({
                        panel: 'FiltersDrillDown',
                        props: {
                          columnFilters: createCombineCondition(
                            props.conditions
                          ),
                          title: 'Violated Controls',
                          subtitle: `Select controls from this list to filter for resources with  ${priority === 'muted' ? priority : `${priority}-severity`} violations`,
                          Component: ({ filter }) => (
                            <ControlsListFilter
                              filter={filter}
                              onChange={props.onFilterChange}
                              priority={priority}
                            />
                          ),
                          onBack: (columnFilters) =>
                            props.renderFiltersPanel(
                              columnFilters,
                              backupStatus.name
                            ),
                        },
                      });

                      event.stopPropagation();
                    }}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export const BackupStatusFilterRow = ({
  status,
  onClick,
  selectedStatus,
}: FilterProps) => {
  const selected = selectedStatus === status;

  return (
    <Stack
      sx={(theme) => ({
        margin: '0 -24px',
        padding: '12px 0 12px 22px',
        borderLeft: '2px solid transparent',
        borderColor: selected ? '#272742' : 'transparent',
        backgroundColor: selected
          ? 'var(--mui-palette-background-default)'
          : 'transparent',
        cursor: selected ? 'default' : 'pointer',
        '.info-icon': {
          visibility: 'hidden',
        },
        '&:hover .info-icon': {
          visibility: 'visible',
        },
        ...(selected
          ? {}
          : {
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#272742' : 'white',
              },
            }),
      })}
      onClick={() => onClick(status)}
      direction={'row'}
      alignItems={'center'}
    >
      <BackupStatusIcon backupStatus={status} />
      <Typography className='ml-[8px]'>
        {BackupStatuses[status].title}
      </Typography>
      <Tooltip
        title={
          <Box>
            {BackupStatuses[status].description}
            {BackupStatuses[status].recommendation && (
              <Divider
                className='my-[8px]'
                sx={{
                  borderColor: 'rgba(39, 39, 66, 0.90)',
                }}
              />
            )}
            {BackupStatuses[status].recommendation && (
              <Stack direction={'row'}>
                <Icon
                  sx={(theme) => ({
                    marginRight: '8px',
                    '& i': {
                      color:
                        theme.palette.mode === 'light'
                          ? 'var(--mui-palette-common-white)'
                          : 'rgba(39, 39, 66, 0.90)',
                    },
                  })}
                  iconClass='material-symbols-circle-notifications-outline-rounded'
                />
                {BackupStatuses[status].recommendation}
              </Stack>
            )}
          </Box>
        }
        slotProps={{
          tooltip: {
            sx: (theme) => ({
              padding: '16px',
              borderRadius: '8px',
              backgroundColor:
                theme.palette.mode === 'light'
                  ? 'rgba(39, 39, 66, 0.90)'
                  : 'var(--mui-palette-common-white)',
            }),
          },
        }}
      >
        <i className='info-icon absolute right-[24px] material-symbols-info-outline h-[20px] w-[20px] p-[2px] cursor-default' />
      </Tooltip>
    </Stack>
  );
};

function findFirstControl(
  selectedResources: string[],
  controls: Control[] | undefined
) {
  for (const id of selectedResources) {
    const control = controls?.find((x) => x.id === id);
    if (control) {
      return control;
    }
  }
}
