import { Box, styled } from '@mui/material';
import Image, { type StaticImageData } from 'next/image';
import { forwardRef, type ComponentProps } from 'react';

import { ApplicationsLogos } from '@/data/inventory/data';

const _CircleImage = forwardRef<
  HTMLSpanElement,
  {
    alt: string;
    src: StaticImageData;
  } & ComponentProps<typeof Box>
>(({ alt, src, ...props }, ref) => {
  return (
    <Box component={'span'} ref={ref} {...props}>
      <Image alt={alt} src={src || ApplicationsLogos.Unknown} height={30} />
    </Box>
  );
});

export const CircleImage = styled(_CircleImage)((props) => ({
  height: '32px',
  width: '32px',
  backgroundColor:
    props.theme.palette.mode === 'dark' ? '#f5f5f5' : 'transparent',
  display: 'inline-flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',

  ':last-of-type': {
    marginRight: 0,
  },
}));
