import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const Tag = forwardRef(
  ({ content, ...rest }: { content: string }, ref) => (
    <Box
      {...rest}
      ref={ref}
      color={'var(--mui-palette-text-primary)'}
      fontSize={'12px'}
      component={'span'}
      className='px-[8px] py-[4px] rounded-[4px] mr-[4px]'
      sx={{ backgroundColor: 'var(--mui-palette-divider)' }}
    >
      {content}
    </Box>
  )
);
