import { styled } from '@mui/material';

export const SnapshotIndicator = styled('div')(
  (props: { backgroundColor: string; sx?: object }) => ({
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: props.backgroundColor || 'black',
    ...props.sx,
  })
);
