import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { InventoryResourceBackupStatusEnum } from '@repo/api-gw-sdk';

import {
  ListOperator,
  StringOperator,
  isValueCondition,
  type Condition,
  type FilterProperty,
} from '@/types/advanceFilter';

import {
  backupStatus,
  controlId,
  controlLevel,
  mutedControlId,
} from './properties';

import {
  BackupStatusFilterRow,
  ViolationsDetectedFilterRow,
} from '../backupStatus/backupStatusFilterRow';
import { TransactionalTextField } from '../shared/transactionalTextField';

export interface FilterProps {
  property: FilterProperty;
  conditions: Condition[];
  'data-testid'?: string;
  onChange: (conditions: Condition[]) => void;
  renderFiltersPanel: (
    columnFilters: Condition | undefined,
    expandedPropertyName?: string
  ) => void;
}

export const FreeTextFilter = ({
  placeholder,
  property,
  conditions,
  onChange,
  'data-testid': dataTestId,
}: FilterProps & { placeholder: string }) => {
  const condition = conditions.find(
    (x) => isValueCondition(x) && x.property === property.name
  );

  return (
    <FormControl variant='outlined' fullWidth>
      <TransactionalTextField
        showSearchIcon
        showClearIcon
        placeholder={placeholder}
        allowEmptyValue
        data-testid={dataTestId}
        initValue={(isValueCondition(condition) && condition.value?.[0]) || ''}
        onChange={(value) => {
          onChange(
            conditions
              .filter(
                (x) => isValueCondition(x) && x.property !== property.name
              )
              .concat(
                value
                  ? [
                      {
                        type: 'String' as const,
                        property: property.name,
                        operator: StringOperator.Contains,
                        value: [value],
                      },
                    ]
                  : []
              )
          );
        }}
      />
    </FormControl>
  );
};

export const MultiSelectFilter = ({
  property,
  conditions,
  onChange,
  options,
  'data-testid': dataTestId,
}: FilterProps & {
  options: {
    id: string;
    name: string;
    Image?: React.ReactNode;
    description?: React.ReactNode;
  }[];
}) => {
  const condition = conditions.find(
    (x) => isValueCondition(x) && x.property === property.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) || [];

  return (
    <FormControl variant='outlined' fullWidth>
      {options.map(({ id, name, Image, description }) => {
        return (
          <FormControlLabel
            key={id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              i: {
                visibility: 'hidden',
              },
              '&:hover i': {
                visibility: 'visible',
              },
            }}
            control={
              <Checkbox
                inputProps={
                  { 'data-testid': `${dataTestId}-${id}` } as Record<
                    string,
                    string
                  >
                }
                className='p-[8px]'
                size='small'
                checked={selectedResources.includes(id)}
                onChange={(event, value) => {
                  const values = value
                    ? [...selectedResources, id]
                    : selectedResources.filter((x) => x !== id);

                  onChange(
                    conditions
                      .filter(
                        (x) =>
                          isValueCondition(x) && x.property !== property.name
                      )
                      .concat(
                        values.length
                          ? [
                              {
                                type: 'List' as const,
                                property: property.name,
                                operator: ListOperator.In,
                                value: values,
                              },
                            ]
                          : []
                      )
                  );
                }}
              />
            }
            label={
              <Stack direction={'row'} alignItems={'center'}>
                {Image}
                {name}
                {description && (
                  <Tooltip
                    title={description}
                    slotProps={{
                      tooltip: {
                        sx: (theme) => ({
                          padding: '16px',
                          borderRadius: '8px',
                          backgroundColor:
                            theme.palette.mode === 'light'
                              ? 'rgba(39, 39, 66, 0.90)'
                              : 'white',
                        }),
                      },
                    }}
                  >
                    <i className='absolute right-0 material-symbols-info-outline h-[20px] w-[20px] p-[2px] cursor-default' />
                  </Tooltip>
                )}
              </Stack>
            }
          />
        );
      })}
    </FormControl>
  );
};

export const TagsFilter = ({ property, conditions, onChange }: FilterProps) => {
  const keyPropertyName = `${property.name}.key`;
  const keyCondition = conditions.find(
    (c) => isValueCondition(c) && c.property === keyPropertyName
  );

  const valuePropertyName = `${property.name}.value`;
  const valueCondition = conditions.find(
    (c) => isValueCondition(c) && c.property === valuePropertyName
  );

  return (
    <FormControl variant='outlined' className='flex flex-column gap-[8px]'>
      <TransactionalTextField
        showSearchIcon
        showClearIcon
        placeholder='Search by key'
        allowEmptyValue
        initValue={
          (isValueCondition(keyCondition) && keyCondition.value?.[0]) || ''
        }
        onChange={(newKey) => {
          onChange(
            conditions
              .filter(
                (x) => isValueCondition(x) && x.property !== keyPropertyName
              )
              .concat(
                newKey
                  ? [
                      {
                        type: 'String' as const,
                        property: keyPropertyName,
                        operator: StringOperator.Contains,
                        value: [newKey],
                      },
                    ]
                  : []
              )
          );
        }}
      />
      <TransactionalTextField
        showSearchIcon
        showClearIcon
        placeholder='Search by value'
        allowEmptyValue
        initValue={
          (isValueCondition(valueCondition) && valueCondition.value?.[0]) || ''
        }
        onChange={(newValue) => {
          onChange(
            conditions
              .filter(
                (x) => isValueCondition(x) && x.property !== valuePropertyName
              )
              .concat(
                newValue
                  ? [
                      {
                        type: 'String' as const,
                        property: valuePropertyName,
                        operator: StringOperator.Contains,
                        value: [newValue],
                      },
                    ]
                  : []
              )
          );
        }}
      />
    </FormControl>
  );
};

export const BackupStatusFilter = ({
  conditions,
  onChange,
  renderFiltersPanel,
}: FilterProps) => {
  const selectFilter = (
    status: InventoryResourceBackupStatusEnum | undefined,
    severity?: string
  ) => {
    onChange(
      conditions
        .filter(
          (x) =>
            isValueCondition(x) &&
            x.property !== backupStatus.name &&
            x.property !== controlLevel.name &&
            x.property !== controlId.name &&
            x.property !== mutedControlId.name
        )
        .concat(
          status
            ? [
                {
                  type: 'String' as const,
                  property: backupStatus.name,
                  operator: StringOperator.Equals,
                  value: [status],
                },
              ]
            : []
        )
        .concat(
          severity
            ? [
                {
                  type: 'String' as const,
                  property: controlLevel.name,
                  operator: StringOperator.Equals,
                  value: [severity],
                },
              ]
            : []
        )
    );
  };

  const backupCondition = conditions.find(
    (x) => isValueCondition(x) && x.property === backupStatus.name
  );

  const controlLevelCondition = conditions.find(
    (x) => isValueCondition(x) && x.property === controlLevel.name
  );

  const selectedStatus =
    (isValueCondition(backupCondition) && backupCondition.value?.[0]) ||
    undefined;

  const selectedLevel =
    (isValueCondition(controlLevelCondition) &&
      controlLevelCondition.value?.[0]) ||
    undefined;

  return (
    <Stack>
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.InitialClassification}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
      <Typography variant='subtitle1' className='my-[20px]'>
        BACKED UP BY EON
      </Typography>
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.Protected}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.AllViolationsMuted}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
      <ViolationsDetectedFilterRow
        status={InventoryResourceBackupStatusEnum.ViolationsDetected}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
        selectedLevel={selectedLevel}
        renderFiltersPanel={renderFiltersPanel}
        conditions={conditions}
        onFilterChange={onChange}
      />
      <Typography variant='subtitle1' className='my-[20px]'>
        NOT BACKED UP BY EON
      </Typography>
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.ExcludedFromBackup}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.GenericBackups}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
      <BackupStatusFilterRow
        status={InventoryResourceBackupStatusEnum.NotBackedUp}
        onClick={selectFilter}
        selectedStatus={selectedStatus}
      />
    </Stack>
  );
};
