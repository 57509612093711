import {
  InventoryResourceDataClassesEnum,
  InventoryResourceBackupStatusEnum,
  InventoryResourceCloudProviderEnum,
  InventoryResourceEnvironmentEnum,
  InventoryResourceResourceTypeEnum,
} from '@repo/api-gw-sdk';
import type { StaticImageData } from 'next/image';

import ActiveDirectory from '../../../public/images/apps/active-directory.png';
import ApacheHTTPServer from '../../../public/images/apps/apache-http.png';
import ApacheTomcat from '../../../public/images/apps/apache-tomcat.png';
import Cassandra from '../../../public/images/apps/cassandra.png';
import Elasticsearch from '../../../public/images/apps/elasticsearch.png';
import Grafana from '../../../public/images/apps/grafana.png';
import IIS from '../../../public/images/apps/iis.png';
import Jenkins from '../../../public/images/apps/jenkins.png';
import Jira from '../../../public/images/apps/jira.png';
import ApacheKafka from '../../../public/images/apps/kafka.png';
import MongoDB from '../../../public/images/apps/mongodb.png';
import MySQL from '../../../public/images/apps/mysql.png';
import Nginx from '../../../public/images/apps/nginx.png';
import OracleDatabaseServer from '../../../public/images/apps/oracle.png';
import PostgreSQL from '../../../public/images/apps/postgres.png';
import Redis from '../../../public/images/apps/redis.png';
import SAPApplicationServer from '../../../public/images/apps/sap-application-server.png';
import Unknown from '../../../public/images/apps/unknown.png';
import AWSLogo from '../../../public/images/providers/aws.png';
import AzureLogo from '../../../public/images/providers/azure.png';
import GCPLogo from '../../../public/images/providers/gcp.png';

export const ApplicationsLogos: Record<string, StaticImageData> = {
  Elasticsearch,
  Cassandra,
  MongoDB,
  MySQL,
  'Oracle Database Server': OracleDatabaseServer,
  PostgreSQL,
  Redis,
  'SAP Hanna': SAPApplicationServer,
  'Apache Kafka': ApacheKafka,
  'Apache HTTP Server': ApacheHTTPServer,
  'Apache Tomcat': ApacheTomcat,
  Nginx,
  Grafana,
  Jenkins,
  Jira,
  'SAP Application Server': SAPApplicationServer,
  'Active Directory': ActiveDirectory,
  IIS,
  Unknown,
};

const dataClasses: Record<
  InventoryResourceDataClassesEnum,
  { title: string; description: string }
> = {
  [InventoryResourceDataClassesEnum.Pii]: {
    title: 'Personal Identifiable Information',
    description:
      'Personally identifiable information (PII) is any data that can be used to identify a person. Some examples are name, email, physical address, login information, and IP addresses.',
  },
  [InventoryResourceDataClassesEnum.Phi]: {
    title: 'Protected Health Information',
    description:
      'Personal health information (PHI) refers to a person’s medical history and health records. Some examples are doctor’s notes, diagnoses, and family medical details.',
  },
  [InventoryResourceDataClassesEnum.Fi]: {
    title: 'Financial Information',
    description:
      'Financial information (FI) is a subset of personally identifiable information (PII) that represents a person’s financial data. Some examples are credit card and bank information, and crypto currency addresses.',
  },
  [InventoryResourceDataClassesEnum.Unspecified]: {
    title: 'Unspecified',
    description:
      'The data class is not specified, so it might contain sensitive information.',
  },
};

const cloudProviders: Record<
  InventoryResourceCloudProviderEnum,
  { logo: StaticImageData; displayName: string }
> = {
  [InventoryResourceCloudProviderEnum.Aws]: {
    displayName: 'AWS',
    logo: AWSLogo,
  },
  [InventoryResourceCloudProviderEnum.Gcp]: {
    displayName: 'GCP',
    logo: GCPLogo,
  },
  [InventoryResourceCloudProviderEnum.Azure]: {
    displayName: 'Azure',
    logo: AzureLogo,
  },
};

const resourceTypeToCloudProvider: Record<
  InventoryResourceResourceTypeEnum,
  InventoryResourceCloudProviderEnum
> = {
  [InventoryResourceResourceTypeEnum.CloudSql]:
    InventoryResourceCloudProviderEnum.Gcp,
  [InventoryResourceResourceTypeEnum.Ebs]:
    InventoryResourceCloudProviderEnum.Aws,
  [InventoryResourceResourceTypeEnum.Ec2]:
    InventoryResourceCloudProviderEnum.Aws,
  [InventoryResourceResourceTypeEnum.Efs]:
    InventoryResourceCloudProviderEnum.Aws,
  [InventoryResourceResourceTypeEnum.FileShare]:
    InventoryResourceCloudProviderEnum.Azure,
  [InventoryResourceResourceTypeEnum.Gce]:
    InventoryResourceCloudProviderEnum.Gcp,
  [InventoryResourceResourceTypeEnum.Rds]:
    InventoryResourceCloudProviderEnum.Aws,
  [InventoryResourceResourceTypeEnum.S3]:
    InventoryResourceCloudProviderEnum.Aws,
  [InventoryResourceResourceTypeEnum.VirtualMachine]:
    InventoryResourceCloudProviderEnum.Azure,
};

const backupStatuses: Record<
  InventoryResourceBackupStatusEnum,
  {
    icon: string;
    title: string;
    iconColor: string;
    backgroundColor: string;
    description: string;
    recommendation?: string;
  }
> = {
  [InventoryResourceBackupStatusEnum.Protected]: {
    icon: 'material-symbols-check-circle-outline-rounded',
    title: 'Protected',
    iconColor: 'var(--mui-palette-success-main)',
    backgroundColor: '#D4FFEE',
    description:
      'The resource is continuously backed up by Eon and there are no control violations.',
  },
  [InventoryResourceBackupStatusEnum.ViolationsDetected]: {
    icon: 'material-symbols-error-outline-rounded',
    title: 'Violations detected',
    iconColor: 'linear-gradient(180deg, #F1A81B 0%, #F87A21 50%, #F04465 100%)',
    backgroundColor:
      'linear-gradient(180deg, #FEF0E6 0%, #FDF6E7 47%, #FDEAED 100%)',
    description:
      'Resources that violate a backup control, so they’re not compliant.',
    recommendation:
      'We recommend updating your backup policies to fix the violations.',
  },
  [InventoryResourceBackupStatusEnum.NotBackedUp]: {
    icon: 'material-symbols-disc-full-outline-rounded',
    title: 'Not backed up',
    iconColor: 'var(--mui-palette-error-main)',
    backgroundColor: '#FDEAED',
    description:
      'No backup policies apply to these resources, and no one has excluded them from backups.',
    recommendation:
      'We recommend updating your backup policies to include the resources.',
  },
  [InventoryResourceBackupStatusEnum.AllViolationsMuted]: {
    icon: 'material-symbols-motion-photos-paused-outline-rounded',
    title: 'All violations muted',
    iconColor: 'var(--mui-palette-success-main)',
    backgroundColor: '#D4FFEE',
    description:
      'Resources that violate at least one backup control, but someone muted all notifications.',
  },
  [InventoryResourceBackupStatusEnum.ExcludedFromBackup]: {
    icon: 'material-symbols-hide-source-outline-rounded',
    title: 'Excluded from backup',
    iconColor: '#A0A0BB',
    backgroundColor: '#EDEDED',
    description:
      'A team member asked Eon not to back up these resources. They won’t trigger any alerts for backup issues.',
  },
  [InventoryResourceBackupStatusEnum.GenericBackups]: {
    icon: 'ic-outline-brightness-1',
    title: 'Generic backups',
    iconColor: 'var(--mui-palette-warning-main)',
    backgroundColor: '#FDF6E7',
    description:
      'Resources that are backed up, but not by Eon, so you won’t be able to explore the resource files and databases, or restore granular data.',
    recommendation:
      'We recommend updating your policies to back up these resources with Eon.',
  },
  [InventoryResourceBackupStatusEnum.InitialClassification]: {
    icon: 'material-symbols-avg-pace-rounded',
    title: 'Initial classification',
    iconColor: '#338CB6',
    backgroundColor: '#DCEEF5',
    description:
      'Resources that were recently discovered and are being scanned by Eon for the first time. Their statuses will be updated after the scan is done.',
  },
};

export const ControlsSeverity: Record<
  string,
  { color: string; title: string }
> = {
  high: {
    title: 'High',
    color: 'var(--mui-palette-error-main)',
  },
  medium: {
    title: 'Medium',
    color: '#F87A21',
  },
  low: {
    title: 'Low',
    color: 'var(--mui-palette-warning-main)',
  },
  muted: {
    title: 'Muted',
    color: '#CFCFE5',
  },
};

const environments: Record<
  InventoryResourceEnvironmentEnum,
  { title: string; description: string }
> = {
  [InventoryResourceEnvironmentEnum.Prod]: {
    title: 'Production',
    description:
      'The resource is part of a customer-facing or external-facing production environment.',
  },
  [InventoryResourceEnvironmentEnum.ProdInternal]: {
    title: 'Production - Internal',
    description:
      'The resource is part of an internal-only production environment, such as an EC2 instance running Jira Enterprise.',
  },
  [InventoryResourceEnvironmentEnum.Stage]: {
    title: 'Non Production',
    description: `The resource isn't part of a production environment—for example, staging or dev.`,
  },
};

export const DataClasses = Object.fromEntries(Object.entries(dataClasses));
export const Environments = Object.fromEntries(Object.entries(environments));
export const BackupStatuses = Object.fromEntries(
  Object.entries(backupStatuses)
);
export const CloudProviders = Object.fromEntries(
  Object.entries(cloudProviders)
);
export const ResourceTypeToCloudProvider = Object.fromEntries(
  Object.entries(resourceTypeToCloudProvider)
);
